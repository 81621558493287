import React, { useEffect, useState } from "react";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex } from "@chakra-ui/react";
import Medviewer from "../MedViewer/Medviewer";
import Loading from "../MedViewer/components/Loading/loading";
import axios from "axios";
import { useParams } from "react-router-dom";

const OSDViewer = () => {
  const { slideId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [slideData, setSlideData] = useState(null);

  useEffect(() => {
    async function getSlideData() {
      try {
const authorizationKey = new URLSearchParams(window.location.search).get('authorization');
        const response = await axios.get(`https://development-api.exviewer.prr.ai/viewer/v1/slide/${slideId}/?Authorization=${authorizationKey}`);
        setSlideData(response.data);
        console.log(response.data.data.dzi_path);
        setLoading(false);
      } catch (error) {
        setError("Error: Unable to fetch slide data. Please try again later.");
        setLoading(false);
      }
    }
    getSlideData();
  }, [slideId]);
  if (error) {
    return <Box w="100%" p={8}>
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Image loading error!</AlertTitle>
      <AlertDescription>{error}</AlertDescription>
    </Alert>
  </Box>;
  }

  return loading ? (
    <Loading />
  ) : (
    <Flex direction="column" h="100vh">
      <Medviewer
        tile={slideData.data.dzi_path}
        slideData={slideData}
      />
    </Flex>
  );
};

export default OSDViewer;
