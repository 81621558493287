
import './App.css';
import {Routes, Route } from "react-router-dom";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import OSDViewer from "./Components/Viewer/OSDViewer";

const theme = extendTheme({
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
  breakpoints: {
    sm: "768px",
    md: "1200px",
    lg: "1440px",
    xl: "1920px",
    "2xl": "2560px",
  },
  colors: {
    light: {
      100: "#FFF",
      200: "#FAFAFA",
      300: "#ECECEC",
      400: "#00153F",
      500: "#F5F5F5",
      600: "#B00020",
      700: "#3B5D7C",
      800: "#F6F6F6",
      900: "#DEDEDE",
      1000: "#FCFCFC",
      1001: "#E5E5E5",
      1002: "#AEAEAE",
      1003: "#000",
      1004: "#F4F4F4",
      1005: "#1B75BC6E",
    },
  },
  fonts: {
    body: "Inter, arial",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
    paragraph: "14px",
    caseHeader: "14px",
    caseInfo: "12px",
  },
  styles: {
    global: {
      body: {
        bg: "light",
        color: "black",
      },
    },
  },
});
function App() {
  const viewerUrl = "/slide/:slideId";
  return (
    <Routes>
      <Route
        path={viewerUrl}
        element={
             <ChakraProvider theme={theme}>
              <OSDViewer />
             </ChakraProvider>

        }
      />
    </Routes>
  );
}

export default App;
